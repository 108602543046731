<template>
  <section class="he-home-view">
    <v-card class="content">
      <v-row align="center" class="logo" no-gutters>

        <v-col cols="12">

          <h1>Welcome to Helium Registration.</h1>

          <p>The link that brought you here is broken.</p>
          <p>Not your fault.</p>

          <SchemaForm

            v-if="false"
            :schema="schema"
            :errors="{}"

          />
        </v-col>
      </v-row>
    </v-card>
  </section>
</template>

<script>

	import SchemaForm from "@/components/forms/SchemaForm"

  export default {
    name: 'HomeView',

		data: function () {
			return {
				schema: {
					'$id': 'https://apps.mezzanineware/farmer.schema.json',
					'type': 'object',
					'title': 'Farmer',
					'$schema': 'https://json-schema.org/draft/2019-09/schema#',

          "description": "",
          "x-props": {
            "grow": true
          },
          "x-display": "tabs",

					'properties': {

            root: {
              type: 'object',
              title: 'Cookies',
              "x-class": "tab-heading",
              "description": "Minimum data set required for use",

              properties: {
                name: {
                  title: 'name',
                  type: 'string'
                },
              }
            },

            root1: {
              type: 'object',
              title: 'Cookies2',
              "x-class": "tab-heading",

              properties: {
                name: {
                  title: 'name',
                  type: 'string'
                },
              }
            },
					}
				}
			}
		},

    components: {
      SchemaForm
    },
  }

</script>


<style lang="scss">

	.he-home-view {
		padding: 20px 10px 150px;
		max-width: 100vw;

		.app-disconnected .v-snack__content {
			display: flex;
			align-items: center;

			.v-icon {
				display: block;
				margin: 0 0.5em 0 0;
			}
		}

		.post-queue .v-snack__wrapper {
			min-width: unset !important;
			max-width: 50px;
			min-height: unset;

			.v-snack__content {
				padding: 7.5px 0;

				.v-icon {
					margin: 7.5px 15px;
				}

				.count {
					text-align: center;
				}
			}
		}

		.content {
			flex-grow: 1;
			margin: 0 auto;
			max-width: 750px;

			& > .row > .col {
				padding: 32pt 42pt 48pt;
			}

			.schemaLogo {
				max-width: 50%;
				margin: 40pt auto;
			}

			.he-locale {
				position: absolute;
				top: 5px;
				right: 5px;
				width: 75px;

				.flag {
					font-size: 150%;
					position: relative;
					top: 3px;
				}
			}

			.v-input__append-outer svg {
				fill: var(--v-info);
			}
		}

		.footer {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			justify-content: center;
			text-align: center;

			.message {
				display: block;
				margin: 6pt 12pt;
			}

			.links {
				display: flex;
				min-width: 100%;
				justify-content: center;

				.link {
					display: block;
					margin: 0 6pt;
				}
			}
		}
	}

</style>
